import React, { Component } from "react";
import Container from "react-bootstrap/Container";

class Experience extends Component {
  componentDidMount() {
    document.title = "Experience";
  }

  render() {
    return (
      <Container>
        <h2>Experience</h2>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae
          molestias laborum voluptatibus fugiat maxime blanditiis accusantium
          iusto aliquid? Dolores exercitationem vel hic et dolore consequatur
          saepe veniam obcaecati facere odit?
        </p>
      </Container>
    );
  }
}

export default Experience;
